$col-min-width: 200px;
$col-max-width: 350px;
$last-col-min-width: 80px;
$last-col-max-width: 120px;
$z-index-sticky-body: 5;
$z-index-sticky-header: 10;
$border-width: 1.5px;

// ミックスイン: 固定列の基本スタイル
@mixin sticky-col($position, $offset) {
  position: sticky;
  #{$position}: $offset;
}

// ミックスイン: 固定列の境界線スタイル
@mixin sticky-border($side) {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    #{$side}: 0;
    bottom: 0;
    width: $border-width;
    height: 100%;
    box-shadow: $border-width 0 $border-width var(--bs-secondary-middle);
    pointer-events: none;
  }
}

.tableWrapper {
  overflow-x: auto;
}

.table {
  border-collapse: collapse;

  thead {
    z-index: $z-index-sticky-header;

    th {
      height: 38px;
      background-color: var(--bs-secondary-pale);
      position: sticky;
      font-weight: normal;
    }
  }

  td {
    height: 50px;
  }

  th,
  td {
    white-space: nowrap;
    text-align: left;
    min-width: $col-min-width;
    max-width: $col-max-width;
    padding-left: 2rem;
    border-bottom: #e9ecef 1px solid;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    max-width: none;
  }

  // 最後の列の幅を調整
  th:last-child,
  td:last-child {
    min-width: $last-col-min-width; // 最後の列の最小幅
    max-width: $last-col-max-width; // 最後の列の最大幅
  }
}

/* 固定列 */
.stickyCol {
  position: sticky;
  background-color: #ffffff;
  z-index: $z-index-sticky-body;

  &.left1 {
    @include sticky-col(left, 0);
    min-width: $col-min-width;
    max-width: $col-min-width;
  }

  &.left2 {
    @include sticky-col(left, $col-min-width);
    // 固定列の右側に線を引く
    @include sticky-border(right);
  }

  &.right {
    @include sticky-col(right, 0);
    padding-left: 0rem;
    text-align: center;
    // 固定列の左側に線を引く
    @include sticky-border(left);
  }
}

.tableInvalidColumn {
  background-color: #fff4f4;
}

.workTimeSelectBox {
  width: 250px;
}
