.placeholderImage {
  max-width: 320px;
}

.list {
  height: 100% !important;
  padding: 0.8rem;
  overflow-y: auto;
}

.row > *:not(:first-child) {
  padding-left: 0;
}
