.activeItem {
  background-color: var(--bs-secondary-pale) !important;
  &:hover {
    background-color: var(--bs-secondary-pale) !important;
  }
}
.item {
  &:active {
    background-color: var(--bs-primary) !important;
  }
}

.searchList {
  overflow-y: auto;
  max-height: 150px;
}

.inputSearchBox {
  width: 130px;
}

.editTextForm {
  width: 100px;
}

.inputSelectDropDown {
  width: 230px;
}
