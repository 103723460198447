.headerHeight {
  height: 45px;
}

.rowHeight {
  height: 50px;
}

.fileNameCell {
  min-width: 230px;
}

.fileImportCell {
  min-width: 80px;
}
