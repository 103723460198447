.row {
  height: calc(100vh - 56px - 38px - 3rem); // 56px: ヘッダー, 38px: タイトル, マージン合計: 3rem
  & > *:nth-child(n + 2) {
    padding-left: 0;
  }
}

.list {
  padding: 0.8rem;
  overflow: auto;
}

$footer: 70px;
.memberDetail {
  height: calc(100% - #{$footer});
  overflow-y: auto;
}

.skillBadgeContainer {
  border: 1px solid #dee2e6;
  min-height: 2.5rem;
}

.placeholderImage {
  max-width: 320px;
}

.spotMemberBorder {
  margin: 0.25rem -0.5rem !important;
}
