.tableRow {
  height: 2.5rem;
  overflow-y: clip;
  border-top: 1px solid var(--bs-secondary-pale);
  user-select: none;

  &:hover .tableHeader {
    background-color: var(--bs-primary-pale);
  }

  &:hover .tableContent {
    background-color: var(--bs-primary-pale);
  }
}
