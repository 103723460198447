.progressContainer {
  position: relative;
  max-width: 65%; /* 追加ボタン付近までの幅 */
  height: 6px;
  background-color: #e1e3e5;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progressBar {
  height: 100%;
  background-color: var(--bs-danger-rgb);
  transition: width 0.2s ease;
}

.scheduleTypeContainer {
  width: 85%;
}
