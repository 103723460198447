$header: 56px;

.sidebarOpen {
  position: fixed;
  top: #{$header};
  right: 0;
  z-index: 200;
  width: 350px;
  height: 100%;
  font-size: 1.125rem;
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar {
  @extend .sidebarOpen;
  right: -350px;
}

.backdrop {
  position: fixed;
  top: #{$header};
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
