.graphContainer {
  height: 50px;
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--bs-secondary-pale);
}

.scaleBase {
  flex: 1;
  height: 100%;
  max-width: 8px;
}

.scaleEven {
  @extend .scaleBase;

  &:nth-child(odd):nth-child(3n + 1) {
    border-left: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(even):nth-child(3n + 1) {
    border-left: 1px dotted var(--bs-secondary-pale);
  }
}

.scaleOdd {
  @extend .scaleBase;

  &:nth-child(odd):nth-child(3n + 1) {
    border-left: 1px dotted var(--bs-secondary-pale);
  }

  &:nth-child(even):nth-child(3n + 1) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleEven0 {
  @extend .scaleEven;

  &:nth-child(12n) {
    border-right: 1px solid var(--bs-secondary-middle-pale) !important;
  }

  &:nth-child(12n + 1) {
    border-left: 1px solid var(--bs-secondary-middle-pale) !important;
  }
}

.scaleOdd15 {
  @extend .scaleOdd;

  &:nth-child(12n + 9) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(12n + 10) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleEven30 {
  @extend .scaleEven;

  &:nth-child(12n + 6) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(12n + 7) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}

.scaleOdd45 {
  @extend .scaleOdd;

  &:nth-child(12n + 3) {
    border-right: 1px solid var(--bs-secondary-pale);
  }

  &:nth-child(12n + 4) {
    border-left: 1px solid var(--bs-secondary-pale);
  }
}
