$margin1: 0.25rem;
$badgeHeight: 1.5rem;

.skillCompact {
  max-height: calc(($badgeHeight + $margin1) * 2); // バッジ2行分
}

.scheduleTypeName {
  width: 130px; // 作業名を10文字程度で丸める幅
}

.badge {
  height: $badgeHeight;
  line-height: $badgeHeight;
}

.height {
  max-height: 300px;
}

.minWidth {
  min-width: calc(240px - var(--bs-card-spacer-x)); // POPUP_CONTENT_MIN_WIDTHからcardのpaddingを引いた幅
}
