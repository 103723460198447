$searchFieldHeight: 38px;
$searchIconHeight: 18px;

.searchIcon {
  position: absolute;
  top: calc((#{$searchFieldHeight} - #{$searchIconHeight}) / 2);
  left: calc((#{$searchFieldHeight} - #{$searchIconHeight}) / 2);
}

.paddingInput {
  padding-left: 38px !important;
}
