tbody tr:hover > td {
  background-color: var(--bs-primary-pale);
}

.leftStickyCell {
  position: sticky;
  left: 0;
  width: 180px;
  min-width: 180px;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}

.cell {
  width: 100%;
  min-width: 140px;
}

.rightStickyCell {
  position: sticky;
  right: 0;
  width: 180px;
  min-width: 180px;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    box-shadow: 1.5px 0 1.5px var(--bs-secondary-middle);
  }
}
