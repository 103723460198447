.scheduleTypeNameContainer {
  width: 180px; // 10文字で丸め込む横幅
}

.buttonContainer {
  width: 120px;
}

.scheduleMappingSettingWidth {
  max-width: 203px; // エラー時のビックリアイコンと余白が、テキストボックスを引き延ばしてしまうのを制御
}
