.container {
  height: calc(100% - 64px);
}

.help {
  background-color: #fff6df;
}

.spotWorkerTable {
  // basisに0を指定すれば、表をスクロールできる
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}

.placeholderImage {
  max-width: 320px;
}
